<!-- 招生系统基础配置 -- 员工账号管理 -- 列表 -->
<template>
  <div>
    <list-template
        :loading="loading"
        :search-config="searchConfig"
        @onSearch="onSearch"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        @onAdd="$router.push('./add')"
        has-add
        :current-page="page"
        @onChangePage="handleCurrentChange"
        @onHandle="handleEdit"
    ></list-template>
    <el-dialog title="禁用账号" :visible.sync="dialogShow" @close="$refs.form.resetFields();$forceUpdate()">
      <el-form ref="form" :model="dialogForm" :rules="rules">
        <el-form-item label="账号禁用原因" label-width="120rem" prop="type">
          <el-select v-model="dialogForm.type" placeholder="请选择" :popper-append-to-body='false'>
            <el-option v-for="item in accountConfig.disable" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
				<el-form-item label="离职继承人" label-width="120rem" prop="belonger_id">
					<el-select v-model="dialogForm.belonger_id" :popper-append-to-body='false' :remote-method="onBelonger" filterable placeholder="请选择" remote reserve-keyword>
						<el-option v-for="item in belongerOption" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
        <el-form-item label="备注" prop="remarks">
          <t-textarea v-model="dialogForm.remarks" show-word-limit overflow maxlength="50" rows="4"></t-textarea>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogShow = false;">取 消</el-button>
        <el-button type="primary" @click="confirmSelectedClass">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 历史记录 -->
    <h-dialog :historyDialogShow="historyDialogShow" :historyList="historyList" @close="historyDialogShow = false"></h-dialog>

  </div>
</template>

<script>
//引入ajax接口管理层的接口函数
import {mapState} from "vuex";
import TTextarea from "@/components/Textarea.vue";
import ListTemplate from "@/components/pages/List.vue"

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  components: {ListTemplate, TTextarea},
  data() {
    return {
      loading:true,
      accountConfig: '',  //账户配置 (含离职原因)
      search: {},
      searchConfig: [
        {prop: "username", placeholder: "查询账号"},
        {prop: "name", placeholder: "查询招生老师姓名"},
      ],
      tableData: [],
			belongerOption: [],
      //总条数
      total: 0,
      pageCount: 0,
      tableConfig: [
        {prop: "username", label: "手机号（账号）"},
        {prop: "name", label: "姓名"},
        {prop: "paper", label: "校区"},
        {prop: "roles", label: "岗位名称"},
        {prop: "department_head", label: "是否为负责人"},
        {prop: "created_at", label: "创建时间"},
        {prop: "creator", label: "创建人"},
        {
          prop: "handle", label: "操作", width: "220rem", handle: true,
          render: (row) => row.status ? ["编辑", "禁用", '查看历史记录'] : ["查看", '查看历史记录']
        },
      ],

      //禁用弹框展示
      dialogShow: false,
      //禁用弹框表单
      dialogForm: {
        id: '',
        status: 0,  //1启动，0禁用，目前业务只有禁用
        type: '',
				belonger_id: '',
        remarks: ''
      },
      rules:{
        type:[{required:true,message:"请选择禁用原因"}],
        remarks:[{required:true,message:"请填写备注"}],
				belonger_id:[{required:true,message:"请选择离职继承人"}],
      },
      disabledId: '',  //点击禁用按钮后暂存该id

      historyDialogShow: false,
      historyList: []
    }
  },
  created() {
    this.$store.commit("setPage", 1);
    this.getData()
    this.getAccountConfig()
  },
  activated() {
    this.getData()
  },
  computed: {
    ...mapState(["page"])
  },
  beforeRouteLeave(to, from, next) {
    // from.meta.keepAlive = true;
    next();
  },
  methods: {
    //获取账户配置
    getAccountConfig(){
      this.$_register('/api/recruit/account/get-param').then(res => {
        this.accountConfig = res.data.data
      })
    },

    onSearch(val) {
      this.$store.commit('setPage', 1);
      this.search = val;
      this.getData(val)
    },

    getData() {
      let params = {...this.search, page: this.page};
      this.loading = true;
      this.$_register('/api/recruit/account', {params}).then(res => {
        let {data} = res.data
        this.tableData = data.list
        this.total = data.page.total
      }).finally(()=>this.loading = false);
    },

    //  点击编辑跳到编辑页面并把row这一行的数据作为参数传递。
    handleEdit(row, text, index) {
      if (text.type === 'edit') {
        this.$router.push('./edit?id=' + row.id)
      }

      if (text.type === 'view') {
        this.$router.push('./details?id=' + row.id)
      }

      if (text.type === 'disable') {
        this.dialogShow = true
        this.disabledId = row.id
				this.belongerOption = []
      }

      if (text.type === 'viewHistory') {
        let params = {
          tbl_name: 'account_data',
          tbl_id: row.id
        }
        this.$_register.get('/api/recruit/common/get-opt-history',{params}).then(res => {
          let data = res.data.data
          this.historyList = data.list.length ? data.list : []
          this.historyDialogShow = true;
        })
      }
    },

    // 当前页改变触发
    handleCurrentChange(val) {
      //当前页赋值给currentPage
      this.$store.commit("setPage", val);
      //拉取数据
      this.getData()
    },

    //账户禁用确认事件
    confirmSelectedClass(){
      this.$refs.form.validate((res,e,p)=>{
        if (res){
          let params = {...this.dialogForm}
          params.id = this.disabledId
          this.$_register.post('/api/recruit/account/state',params).then(res =>{
            if(res.data.status === 0){
              this.$message({
                type: 'success',
                message: '禁用成功',
                onClose: () => {
                  this.dialogShow = false
                  this.getData()
                }
              });
            }
          })
        }
      })
    },

		// 离职继承人搜索
		onBelonger(val) {
			console.log(val)
			this.$_register.get('api/recruit/account/belonger\n',{params: {keyword:val}}).then(res => {
        this.belongerOption = res.data.data
				console.log(res.data.data)
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.role-list {
  padding-top: 24rem;
}

.button {
  // height: 100rem;
  //  padding-top: 30rem;
  margin-left: 34rem;
  margin-bottom: 30rem;
  box-sizing: border-box;
  width: 90rem;
  height: 34rem;
  //background: rgba(24, 24, 120);
  opacity: 1;
  border-radius: 4rem;
  // display: flex;
  // .button-item {
  // width: 120rem;
  // font-size: 25rem;

  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // .additem{
  //   // font-size:20rem;
  //   display: flex;
  //   // justify-content: space-between;
  //   align-items: center;
  //   padding-left:3rem;
  //   //  word-spacing:10rem;
  //   .item{
  //     margin-left:10rem;
  //   }
  // }
}

// }
.table {
  margin-left: 32rem;
}



::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
  text-align: left;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  height: 48rem;
  box-sizing: border-box;
  color: #333333;
}

.header {
  position: fixed;
  top: 120rem;
  left: 300rem;
  font-size: 16rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 24rem;
  color: #333333;
  letter-spacing: 3.5rem;
}

.el-button--text {
  margin-right: 40rem;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: #3491fa;
}
</style>
